<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="otp_container" slot="component">
      <div v-if="showForm">
        <div class="otp_container_header">
          <p v-if="!enableOtp">{{ enterMobileNumber() }}</p>
          <p v-else>{{ $t("Please enter the OTP Sent to your mobile number") }}</p>
        </div>

        <div class="otp_container_error form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
        </div>

        <form @submit.prevent="enableOtp ? verifyOtp() : paymentInit(planInfo)">
          <div class="otp_container_mobile_number" v-if="!isMobileno">
            <!-- <input
              type="number"
              id="mobileno"
              v-model="mobilenumber"
              onkeypress="return this.value.length > 9 ? false :true"
            /> -->

            <div id="input-container" class="lookup-form-container-input-container ph-number-wrapper" dir="ltr">
              <!-- <div id="countrycode" class="lookup-form-container-input-container-select-code"> -->
                <input
                  v-if="mobilePrefix"
                  type="text"
                  v-model="mobilePrefix"
                  placeholder="+91"
                  id="mobilePrefixDisplay"
                  class="lookup-form-container-input-container-select-code country-code-block"
                  :class="[localDisplayLang === 'ara' ? 'rightPad' : '']"
                  disabled
                />
              <!-- </div> -->
              <input
                id="mobileno"
                class="lookup-form-container-input-container-inputtext"
                type="text"
                dir="ltr"
                v-model="mobilenumber"
                autocomplete="false"
                @keydown.space.prevent
              />
            </div>
          </div>

          <div class="otp_container_body" v-if="enableOtp">
            <div id="pininputs" class="pin-inputs" dir="ltr">
              <input
                v-for="(item, index) in numberOfOtpBlocks"
                :key="index"
                class="pin"
                type="text"
                v-model="userPin[item - 1]"
                @input="grabPin"
                maxlength="1"
              />
            </div>
          </div>

          <div class="otp_container_button">
            <button class="button-primary" type="submit">
              <span v-if="!loader && enableOtp">{{ $t("Verify") }}</span>
              <span v-else-if="!loader && !enableOtp">{{ $t("continue") }}</span>
              <Loading v-else></Loading>
            </button>
          </div>
        </form>

        <div class="otp_container_planText">
          <p>{{ planTermsText(planInfo) }}</p>

          <p v-if="planInfo.isEtisalatUAE" class="t-and-c-text">
            {{ $t("For complete T’s &C’s") }}
            <span class="click" @click="redirectTermsAndConditions()">{{ $t("Click here") }}</span>
          </p>
        </div>

        <!-- <div v-if="planInfo.isEtisalatUAE" class="otp_container_planText">
          {{ $t("For complete T’s &C’s") }}
          <span class="click" @click="redirectTermsAndConditions()">{{ $t("Click here") }}</span>
        </div> -->
      </div>
      <div v-else>
        <div class="payment-progress-container" v-if="!transactionstatus">
          <p class="please-wait">{{ $t("please wait") }}</p>
          <p class="payment-progress">{{ $t("your payment is in progress") }}</p>
          <img class="loading-gif" src="@/assets/gif/30.gif" />
        </div>
        <div v-else>
          <p class="payment_response_text">{{ paymentResponse }}</p>
          <div class="success_card" v-if="transactionSuccess">
            <img class="tick" src="@/assets/icons/Check1.svg" />
            <p class="plan-title">{{ planInfo.planname }}</p>
            <p class="plan-interval" dir="ltr" v-if="localDisplayLang === 'ara'">
              {{ $t(planInfo.planinterval) + " " + "/" + " " + planInfo.currency + " " + planInfo.amount / 100 }}
            </p>
            <p class="plan-interval" dir="ltr" v-else>
              {{ planInfo.currency + " " + planInfo.amount / 100 + " " + "/" + " " + $t(planInfo.planinterval) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    paymentInfo: {
      type: Object,
    },
    planInfo: {
      type: Object,
    },
    gatewayInfo: {
      type: Object,
    },
    isMobileno: {
      type: Boolean,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      profilePin: "",
      formError: null,
      loader: false,
      showForm: true,
      paymentResponse: "",
      transactionSuccess: false,
      transactionstatus: null,
      localDisplayLang: null,
      counter: 10,
      mobilenumber: null,
      mobilePrefix: "",
      enableOtp: false,
      paymentInitResponse: null,
      countryList: [],

      numberOfOtpBlocks: 4,

      teleCode: null
    };
  },
  computed: {
    ...mapGetters(["getCountry", "appConfig"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },
    enableOtp(val) {
      if (val) {
        this.setupPin();
      }
    },
  },
  created() {
    if (this.paymentInfo !== undefined) {
      this.paymentInitResponse = this.paymentInfo;
    } else {
      this.getCountries();
    }

    if (this.planInfo && this.planInfo.isZainKsa) {
      this.numberOfOtpBlocks = 6;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.enableOtp = this.isMobileno ? true : false;
  },
  mounted() {
    eventBus.$on("paymentDetail-response", (response) => {
      console.log("payment status response", response);
      this.transactionstatus = response.transactionstatus;
      if (this.transactionstatus === "PENDING") {
        this.paymentResponse = "Payment status is pending";
      } else if (this.transactionstatus === "SUCCESS") {
        this.paymentResponse = this.$t("You have successfully subscribed");
        this.transactionSuccess = true;
        this.setNewSubscription(true);
      } else if (this.transactionstatus === "FAILED") {
        this.paymentResponse = "Your payment is failed";
      }
    });
  },
  methods: {
    ...mapMutations(["setNewSubscription"]),
    ...mapActions(["getCountryList", "paymentInitiation", "OTPPaymentConfirmation"]),

    redirectTermsAndConditions() {
      this.$router.push("/termsofuse");
    },

    enterMobileNumber() {
      if (this.localDisplayLang == "ara") {
        return this.appConfig.messagesList.ar.enterMobileNumber;
      } else {
        return this.appConfig.messagesList.en.enterMobileNumber;
      }
    },

    planTermsText(plan) {
      console.error("NEW PLAN --- ", plan);

      if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged OMR 0.7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 12 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 30 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
      
      else if (plan.currency === "QAR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged QAR 7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (plan.currency === "QAR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged QAR 20 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `Once your 2 days free trial ends, you will be charged AED 7 weekly. Renewals will be automatic every weekly. To cancel the service anytime, just send ‘’STOP 12” to 9827. Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `Once your 7 days free trial ends, you will be charged AED 20 monthly. Renewals will be automatic every monthly. To cancel the service anytime, just send ‘’STOP 13” to 9827. Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `
          After 2 days free trial, you will be charged 7 AED / Week automatically 
          No commitment, you can cancel any time by sending C NPW to 1111 
          For support, please contact
          support@noorplay.com
          Free trial applicable only for first time subscriber
          Enjoy your Free trial until 2 days.`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `
          After 7 days free trial, you will be charged 20 AED / Month automatically 
          No commitment, you can cancel any time by sending C NPM to 1111 
          For support, please contact
          support@noorplay.com
          Free trial applicable only for first time subscriber
          Enjoy your Free trial until 7 days`;
      }
      //
      else if (plan.currency == "SAR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Once your 7 days free trial ends, you will be charged 20 SAR monthly
              Renewals will be automatic every month
              To cancel the service anytime, just send "UNSUBNM" to 712799
              Free trial is applicable for new customers only`;
      } else if (plan.currency == "SAR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Once your 2 days free trial ends, you will be charged 7 SAR weekly
              Renewals will be automatic every week
              To cancel the service anytime, just send "UNSUBNW" to 712799
              Free trial is applicable for new customers only`;
      }
      //
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 0.7 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 12" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 30 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 
      
      else if (plan.currency === "QAR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 7 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (plan.currency === "QAR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 20 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 2 يوم ) سيتم خصم 7 درهم إماراتي أسبوعياً وسيتم تجديد الإشتراك تلقائياً كل أسبوع
          بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP 12" للرقم 9827
          "التجربة المجانية متوفرة للمشتركين الجدد فقط"`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 20 درهم إماراتي شهرياً وسيتم تجديد الإشتراك تلقائياً كل شهر
          بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP 13" للرقم 9827
          "التجربة المجانية متوفرة للمشتركين الجدد فقط"`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `إحصل على ( 2 يوم ) تجربة مجانية عند الإشتراك بالباقة الأسبوعية
          عند إنتهاء التجربة المجانية سيتم احتساب رسوم الخدمة ( 7 درهم إماراتي ) أسبوعيا متضمن الضريبة
          سيتم تجديد الإشتراك بشكل تلقائي كل أسبوع
          بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال
          C NPW للرقم  1111 
          في حال أي سؤال أو إستفسار نرجوا التواصل عبر
          support@noorplay.com
          التجربة المجانية متوفرة مرة واحدة وللمشتركين الجدد فقط
          `;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `إحصل على ( 7 أيام ) تجربة مجانية عند الإشترك بالباقة الشهرية
          عند إنتهاء التجربة المجانية سيتم احتساب رسوم الخدمة ( 20 درهم إماراتي ) شهريا متضمن الضريبة
          سيتم تجديد الإشتراك بشكل تلقائي كل شهر
          بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال
          C NPM للرقم  1111 
          في حال أي سؤال أو إستفسار نرجوا التواصل عبر
          support@noorplay.com
          التجربة المجانية متوفرة مرة واحدة وللمشتركين الجدد فقط`;
      }
      //
      else if (plan.currency == "SAR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `عند إنتهاء الفترة التجريبية ( 2 أيام ) سيتم خصم 7 ريال سعودي اسبوعيا
              سيتم تجديد الإشتراك تلقائيا كل اسبوع
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNW" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      } else if (plan.currency == "SAR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 20 ريال سعودي شهريا
              سيتم تجديد الإشتراك تلقائيا كل شهر
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNM" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      }
    },

    calculatePrice(item) {
      return item.amount / 100;
    },
    setupPin() {
      setTimeout(() => {
        // this.getId = document.getElementById("pininputs");
        this.pinFunctionality(document.getElementById("pininputs"));
      }, 1000);
    },
    grabPin() {
      if (this.userPin.length > this.numberOfOtpBlocks - 1) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = parseInt(pin);
      }
    },
    getCountries() {
      store
        .dispatch("getCountryList")
        .then((response) => {
          this.countryList = response;
          if (this.getCountry) {
            this.fetchCurrentCountryCode();
          }
        })
        .catch((error) => console.log(error));
    },
    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        console.log("THE COUNTRT CODE --- ", element.code, this.getCountry.CountryCode);
        // return element.code === this.getCountry.CountryCode;
        return element.code === this.planInfo.country[0];
      });

      if (index > -1) {
        this.mobilePrefix = this.countryList[index].dial_code ? this.countryList[index].dial_code : "";
      }
    },
    constructInitParams(item) {
      let payload = {
        amount: item.amount,
        currency: item.currency,
        devicetype: "WEB",
        transactionpurpose: "SUBSCRIPTION",
        transactionmode: "CC",
        availabilityid: item.availabilityset[0],
        planid: item.planid,
      };

      console.log("THE PREFIX", this.mobilePrefix);

      if (this.mobilenumber) {
        payload.mobileno = this.mobilePrefix + this.mobilenumber;
      }

      return payload;
    },
    paymentInit(plan) {
      if (this.mobilenumber) {
        let payload = {
          gatewayid: this.gatewayInfo.gatewayid,
          params: this.constructInitParams(plan),
        };

        let currentDomain = window.location.origin;

        if (plan.hasOwnProperty("isSTC") && plan.isSTC) {
          payload.params.return_url = currentDomain + "/profile" + "?" + "planid=" + plan.planid;
        } else if (
          (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
          (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
        ) {
          payload.params.client_return_url = currentDomain + "/profile" + "?" + "planid=" + plan.planid;
        }

        this.loader = true;
        this.paymentInitiation(payload)
          .then((response) => {
            this.loader = false;
            console.log("payment init response", response);
            if (response.data.reason) {
              this.formError = this.$t(response.data.reason);
            } else {
              if (
                (plan.hasOwnProperty("isSTC") && plan.isSTC) ||
                (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
                (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
              ) {
                let redirectURL = "";
                if (response.data.referencedata && response.data.referencedata.paymenturl) {
                  redirectURL =
                    response.data.referencedata.paymenturl + `?&lang=${this.localDisplayLang === "ara" ? "ar" : "en"}`;
                } else if (response.data.referencedata.checkoutUrl) {
                  redirectURL = response.data.referencedata.checkoutUrl;
                }
                window.open(redirectURL, "_self");
              } else {
                this.disableInputField();
                this.paymentInitResponse = response;
                console.log("ENABLE OTP ", this.enableOtp)
                this.enableOtp = true;
              }
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.formError = this.enterMobileNumber();
      }
    },
    verifyOtp() {
      if (this.profilePin) {
        this.paymentInitResponse.data.referencedata.otpverify = this.profilePin;
        this.paymentInitResponse.data.referencedata.gwprovider = this.gatewayInfo.gatewayid;

        if (this.mobilenumber) {
          this.paymentInitResponse.data.referencedata.mobileno = this.mobilePrefix + this.mobilenumber;
        }

        let payload = {
          gatewayid: this.gatewayInfo.gatewayid,
          params: {
            referenceid: this.paymentInitResponse.data.referenceid,
            referencedata: JSON.stringify(this.paymentInitResponse.data.referencedata),
          },
        };

        console.log("payload for verifyOtp", payload);

        this.loader = true;
        this.OTPPaymentConfirmation(payload)
          .then((response) => {
            this.loader = false;
            console.log("paymentConfirmation response", response);
            if (response.data.reason) {
              this.formError = response.data.reason;
            } else {
              this.showForm = false;
              //show timer for customer and then call payment status api.
              this.decrementCounter(response);
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.formError = this.$t("Please Enter OTP");
      }
    },
    //showing loader for 10 seconds so the payment status will be changed to success for razorpay.
    decrementCounter(callbackResponse) {
      if (this.counter > 1) {
        let timer = setInterval(() => {
          this.counter--;
          if (this.counter < 0) {
            clearInterval(timer);
            this.checkPaymentStatus(callbackResponse);
          }
        }, 1000);
      }
    },
    checkPaymentStatus(callbackResponse) {
      let payload = {
        referenceid: callbackResponse.data.referenceid,
      };
      eventBus.$emit("paymentDetails", payload);
    },

    disableInputField() {
      let dom = document.getElementById("mobileno");
      dom.disabled = true;
      dom.style.color = "rgba(255,255,255, 0.3)";
      dom.style.cursor = "not-allowed";

      // Disable the country Code fix
      let prefix = document.getElementById("mobilePrefixDisplay");
      prefix.disabled = true;
      prefix.style.color = "rgba(255,255,255, 0.3)";
      prefix.style.cursor = "not-allowed";
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],
  beforeDestroy() {
    eventBus.$off("paymentDetail-response");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./telcoOtp.scss"
</style>
